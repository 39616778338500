import React, {useRef} from "react";
import {useRouter} from "next/router";
import {serviceLinks} from "./data";
// Toaster
import "react-toastify/dist/ReactToastify.css";
// scss
import styles from "./headerSection.module.scss";
// Html Parser
import dynamic from 'next/dynamic';
import Link from "next/link";
import {DEFAULT} from "../../helper/constantHelper";
import useDeviceWidth from "../../helper/useDeviceWidth";
// API and JWT helper
const Header = dynamic(() => import("./header"));

const metaTag = require('../../public/private-page-meta.json');
const HeaderSection = (props) => {
  const router = useRouter();
  const selectRef = useRef(null);

  // order submit function
  const orderSubmitHandler = () => {
      const currMeta = metaTag.filter(
          (item) => item.file_name === 'order'
      );

      const doesLocaleExist = router.locale;

      ga('send', 'pageview', {
        page: doesLocaleExist && router.locale !== DEFAULT ? `${router.locale}/order` : `order`,
        title: currMeta && currMeta.length > 0 ? currMeta[0].title : ''
      });

      const orderPath = doesLocaleExist && router.locale !== DEFAULT ? `/${router.locale}/order` : `/order`;
      router.push(orderPath);
  }
  const serviceData = props.serviceData ? props.serviceData : "";

  const {
      service_type_data,
      paper_types_data,
      display_pages_data,
      heroImage,
      currLocale,
      linkLocale
  } = props;
  const {width} = useDeviceWidth();

  return <>
    {/* Navbar */}
    <div className={`${currLocale === DEFAULT ? "" : styles.whiteColor} ${styles.topNavbar}`}>
      {/* header */}
      <Header serviceData={serviceData}
              orderSubmitHandler={orderSubmitHandler}
              service_type_data={service_type_data}
              paper_types_data={paper_types_data}
              display_pages_data={display_pages_data}
              selectRef={selectRef}
              heroImage={heroImage}
              currLocale={currLocale} 
            />

        {width > 991 && (
            <div className={`${styles.headerBottom} d-none d-sm-block`}>
                <div className="container">
                  {(
                      router.pathname && (router.pathname === "/" || router.pathname === "/index")
                  )  && (
                      <ul className={styles.list}>
                        {serviceLinks.map(function (item) {
                          return (
                              <li key={item.href}>
                                <Link
                                    href={item.href}
                                    locale={linkLocale}
                                    prefetch={false}
                                    className={styles.listLink}>

                                    {item.link}

                                </Link>
                              </li>
                          );
                        })}
                      </ul>
                  )}
                </div>
            </div>
        )}
    </div>
  </>;

};

export default HeaderSection;